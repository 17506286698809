import config from './config';
import firebase from '../utils/firebase';
import { parseJSON, isValid, format } from 'date-fns';
import memberCache from '../caches/memberCache';
import utils from './utils';
import groupsCache from '../caches/groupsCache';
import authCache from '../caches/authCache';
import moment from 'moment';
import DateUtils from './DateUtils';
import { v4 as uuidv4 } from 'uuid';

export default class api {
    static DEFAULT_AVATAR = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541';
    static DEFAULT_GROUP_PICTURE = 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png';
    static DEFAULT_EVENT_PICTURE = 'https://www.urbansplash.co.uk/images/placeholder-16-9.jpg';

    static getInitialsImg = (fullname) => {
        const parts = fullname?.split(' ');

        var seed = '';
        if (parts?.length >= 1)
            if (parts[0].length >= 1) seed += parts[0][0];
        if (parts?.length >= 2)
            if (parts[1].length >= 1) seed += parts[1][0];

        return `https://api.dicebear.com/5.x/initials/svg?seed=${seed}`;
    }

    static getIdenticonImg = (name) => {
        const seed = name?.replace(' ', '_');
        return `https://api.dicebear.com/5.x/identicon/svg?seed=${seed}`;
    }

    static getShapesImg = (name) => {
        const seed = name?.replace(' ', '_');
        return `https://api.dicebear.com/5.x/shapes/svg?seed=${seed}`;
    }

    static async getLatestUserToken() {
        var userIdToken;
        var currentUser = firebase.auth().currentUser;
        if (currentUser) {
            userIdToken = currentUser?.getIdToken();
        }
        else {
            var user = await firebase.auth();
            userIdToken = await user?.currentUser?.getIdToken();
        }


        if (userIdToken === undefined) return '';
        return userIdToken;
    }

    static async getRequestHeaders() {
        let headers = new Headers();
        let token = await this.getLatestUserToken();
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', token);
        return headers;
    }

    static normalizeMember = async (member) => {
        member.modified = member.modified ? parseJSON(member?.modified) : null;
        member.dob = member.dob ? parseJSON(member?.dob) : null;
        member.created = member.created ? parseJSON(member?.created) : null;
        member.joined = member.joined ? parseJSON(member?.joined) : null;

        const allGroups = [];
        for (var groupId of member.groupIds ?? []) {
            const group = await groupsCache.GetGroup(groupId);
            allGroups.push(group);
        }

        member.groups = allGroups?.sort((a, b) => (a?.memberIds?.length ?? 0) < (b?.memberIds?.length ?? 0));
    }

    static getMember(memberId, phoneNumber, email, uid, login = false) {
        var params = [];

        if (memberId) params.push(`id=${memberId}`);
        if (phoneNumber) params.push(`phone=${phoneNumber}`);
        if (email) params.push(`email=${email}`);
        if (uid) params.push(`uid=${uid}`);

        var allParams = params.join('&');

        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/member?${allParams}&login=${login}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    console.debug(`GET member in`, new Date() - start, `ms`);

                    if (res.ok) {
                        var member = await res.json();
                        await this.normalizeMember(member);
                        resolve(member);
                    }
                    else {
                        var text = await res.text();
                        console.error(text);
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.error(err);
                    resolve(null)
                });
        });
    }

    static getMembers() {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/members`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    console.debug(`GET members in`, new Date() - start, `ms`);

                    if (res.ok) {
                        var json = await res.json();
                        var allMembersObj = {};
                        await Promise.all(json?.map(async (member) => {
                            await api.normalizeMember(member);
                            allMembersObj[member.id] = member;
                        }));

                        resolve(allMembersObj);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(err => console.error(err));
        });
    }

    static putOrUpdateMember(method, memberId, body) {
        return new Promise(async (resolve, reject) => {
            var jsonBody = JSON.stringify(body);

            fetch(`${config.endpoint}/member?id=${memberId}`, {
                crossDomain: true,
                method: method,
                headers: await api.getRequestHeaders(),
                body: jsonBody
            })
                .then(async res => {
                    if (res.ok) {
                        var member = await res.json();
                        config.debug && console.debug(`${method} member result:`, member);

                        if (method === "POST") member.enabled = true;
                        await memberCache.AddOrUpdate(member);

                        resolve(member);
                    }
                    else {
                        var text = await res.text();
                        reject(text);
                    }
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    static getTemplate(id) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/template?id=${id}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(response => response.json())
                .then(async data => {
                    config.debug && console.debug(`GET Template`, data)

                    if (data.modified)
                        data.modified = parseJSON(data.modified);

                    resolve(data);
                })
                .catch(err => reject(err));
        });
    }

    static getAllTemplates() {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/templates`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(response => response.json())
                .then(async data => {
                    await Promise.all(Object.values(data).map(async (t) => {
                        const owner = await memberCache.GetSingleMember(t.owner);
                        t.ownerMember = owner;
                    }));

                    // var templates = Object.entries(data).map(entry => {
                    // 	return {id: entry[0], name: entry[1]};
                    // });

                    // config.debug && console.debug(`GET Template Names (${templates.length})`);

                    // templates = templates.sort(function(a, b){
                    //   if(a.name < b.name) { return -1; }
                    //   if(a.name > b.name) { return 1; }
                    //   return 0;
                    // });

                    resolve(data)
                })
                .catch(err => reject(err));
        });
    }

    static normalizeGroup = async (data, placeholderImg = true) => {
        if (data.modified) data.modified = parseJSON(data.modified);
        if (data.created) data.created = parseJSON(data.created);
        if (data.owner) data.ownerMember = await memberCache.GetSingleMember(data.owner);

        var selectedMembers = {};
        await Promise.all(data?.memberIds?.map(async (id) => {
            if (id === 'hidden') return;
            const member = await memberCache.GetSingleMember(id);
            if (!member) {
                config.debug && console.info(`Removing memberId ${id} from group '${data?.name}' because it does not exist`);
                return;
            }

            selectedMembers[id] = member?.fullname;
        }));

        const membersArray = Object.keys(selectedMembers).map(k => ({ id: k, name: selectedMembers[k] })).sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });

        data.memberIds = membersArray.map(m => m.id);
        data.picture = data.picture ?? (placeholderImg ? api.getShapesImg(data.name) : undefined);
    }

    static getGroup(id) {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/group?id=${id}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Group in`, new Date() - start, `ms`);

                        await this.normalizeGroup(data, false);
                        resolve(data);
                    }
                    else {
                        reject(res.status)
                    }
                })
                .catch(err => reject(err));
        });
    }

    static getGroups() {
        return new Promise(async (resolve, reject) => {
            const start = new Date();

            fetch(`${config.endpoint}/groups`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders()
            })
                .then(async (res) => {
                    console.debug(`GET groups in`, new Date() - start, `ms`);

                    if (res.ok) {
                        var groups = await res.json();
                        await Promise.all(groups?.map(async (group) => {
                            await this.normalizeGroup(group);
                        }));

                        resolve(groups)
                    }
                    else {
                        console.error(`Error ${res.status} - Failed to get groups - ${res.statusText}`);
                        resolve([]);
                    }
                })
                .catch(err => {
                    console.error('Failed to get groups', err);
                    resolve([]);
                });
        });
    }

    static parseMembersField = async (members) => {
        var result = await Promise.all(members?.map(async (member) => {
            const fullMember = await memberCache.GetSingleMember(member.id);
            if (!fullMember) {
                console.error(`Clearing memberId ${member.id} because it no longer exists`)
                return null;
            }

            member.avatar = fullMember?.avatar;
            member.fullname = fullMember?.fullname;
            member.phone = fullMember?.phone;
            member.email = fullMember?.email;

            if (member.confirmed_dt) member.confirmed_dt = parseJSON(member.confirmed_dt);
            if (member.notif_sms_dt) member.notif_sms_dt = parseJSON(member.notif_sms_dt);
            if (member.notif_email_dt) member.notif_email_dt = parseJSON(member.notif_email_dt);

            api.parseMemberConfirmation(member);

            return member;
        }));
        // filter out the null (unresolved members)
        return result.filter(m => m);
    }

    static parseEventFields = async (fields) => {
        for (var field of fields ?? []) {
            if (field.timestamp) field.timestamp = parseJSON(field.timestamp);

            field.members = await api.parseMembersField(field.members);
            field.declinedMembers = await api.parseMembersField(field.declinedMembers);

            //  clear deleted members from list..
            field.members = field.members?.filter(f => f !== null);
            field.members = utils.sortMembersByAlpha(field.members);

            field.memberIds = field.members.map(m => m.id) ?? [];

            // do not double count - currently declined members that are still active, should not show as historically declined..
            const activeMemberIds = new Set(field.memberIds);
            field.declinedMembers = field.declinedMembers?.filter(member => {
                return !activeMemberIds.has(member?.id);
            });

            field.declinedMembers = utils.sortMembersByConfirmation(field.declinedMembers);

            // clear no longer existing groups..
            field.groupIds = await Promise.all((field?.groupIds ?? []).map(async (groupId) => {
                const group = await groupsCache.GetGroup(groupId);
                if (!group) {
                    console.log(`Clearing groupId ${groupId} because it no longer exists`)
                    return null;
                }

                return groupId;
            }))

            field.groupIds = field.groupIds?.filter(id => id !== null);

            field.rotations?.forEach(r => {
                r.id = uuidv4();
            });
        }

        return fields;
        // we no longer want to reorder fields on client because user can reorder fields..
        // var reorderFields = fields.some(f => f.timestamp);
        // if (!reorderFields) return fields;

        // const sortedFields = fields.sort((a, b) => {
        // 	return a.timestamp > b.timestamp;
        // });

        // return sortedFields;
    }

    static parseMemberConfirmation = (member) => {
        var confirmed_dt = parseJSON(member.confirmed_dt);
        var notif_sms_dt = parseJSON(member.notif_sms_dt);
        var notif_email_dt = parseJSON(member.notif_email_dt);
        var confirmedDetails = '';

        var confirmedTime = null;
        if (isValid(confirmed_dt)) {
            confirmedDetails += 'Confirmed - ' + format(confirmed_dt, 'MM/dd/yyyy') + ' at ' + format(confirmed_dt, 'h:mm aa') + '\n';
            confirmedTime = format(confirmed_dt, 'MM/dd/yyyy') + ' at ' + format(confirmed_dt, 'h:mm aa');
        }

        if (isValid(notif_sms_dt))
            confirmedDetails += 'SMS - ' + format(notif_sms_dt, 'MM/dd/yyyy') + ' at ' + format(notif_sms_dt, 'h:mm aa') + '\n';

        if (isValid(notif_email_dt))
            confirmedDetails += 'Email - ' + format(notif_email_dt, 'MM/dd/yyyy') + ' at ' + format(notif_email_dt, 'h:mm aa') + '\n';

        if (member.confirmed === false && member?.confirmed_reason)
            confirmedDetails += `Declined Reason - "${member.confirmed_reason}"`

        member.confirmedTime = confirmedTime;
        member.confirmedDetails = confirmedDetails;
    }

    static parseEvent = async (event) => {
        event.start = DateUtils.tryParse(event.start);
        event.end = DateUtils.tryParse(event.end);
        event.updated = DateUtils.tryParse(event.updated);
        event.created = DateUtils.tryParse(event.created);

        for (var notif of event.scheduled_notifs) {
            notif.notify_date = DateUtils.tryParse(notif.notify_date);
            notif.notify_time = isValid(notif.notify_date) ? moment(notif.notify_date) : moment();
        }

        if (!event.schedule) event.schedule = [];
        if (!event.staff) event.staff = [];
        if (!event.attending) event.attending = [];

        event.schedule = await this.parseEventFields(event.schedule);
        event.staff = await this.parseEventFields(event.staff);
        event.attending = await this.parseEventFields(event.attending);

        this.keyEventFields(event);
        return event;
    }

    static getEvent(eventId, memberId) {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/events?ids=${eventId}&memberId=${memberId}`,
                {
                    headers: await api.getRequestHeaders(),
                })
                .then(async res => {
                    config.debug && console.debug(`GET Event in`, new Date() - start, `ms (id: ${eventId})`);

                    if (res.ok) {
                        const events = await res.json();
                        const event = await this.parseEvent(events[0]);

                        resolve(event);
                    }
                    else {
                        reject(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(err)
                });
        });
    }

    static getEventViaShortId(shortEventId) {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/shortEvent?id=${shortEventId}`,
                {
                    headers: await api.getRequestHeaders(),
                })
                .then(async res => {
                    config.debug && console.debug(`GET Event in`, new Date() - start, `ms (id: ${shortEventId})`);

                    if (res.ok) {
                        const data = await res.json();
                        const memberId = data.memberId;
                        const event = await this.parseEvent(data.event);

                        resolve({ event, memberId });
                    }
                    else {
                        reject(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(err)
                });
        });
    }

    static getTemplateReport(templateId, start, end) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/reports?templateId=${templateId}&start=${JSON.stringify(start)}&end=${JSON.stringify(end)}}`,
                {
                    headers: await api.getRequestHeaders(),
                })
                .then(async res => {
                    config.debug && console.debug(`GET Report in`, new Date() - start, `ms (id: ${templateId})`);

                    if (res.ok) {
                        const data = await res.json();
                        console.log(data)
                        data.points.forEach(r => {
                            r.date = DateUtils.tryParse(r.date);
                        });
                        resolve(data);
                    }
                    else {
                        reject(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(err)
                });
        });
    }

    static getEventPlans(templateId, start, end, previewMode, dates, rotationHistory) {
        var startTime = new Date();
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/templatePlans?templateId=${templateId}&start=${JSON.stringify(start)}&end=${JSON.stringify(end)}&future=${previewMode}&past=${true}`,
                {
                    headers: await api.getRequestHeaders(),
                    body: JSON.stringify({
                        dates,
                        history: rotationHistory,
                    }),
                    method: 'POST'
                })
                .then(async res => {
                    if (res.ok) {
                        const data = await res.json();

                        function parsePlan(plan) {
                            plan.start = DateUtils.tryParse(plan.start);
                            plan.end = DateUtils.tryParse(plan.end);

                            var obj = {};
                            plan.schedule.forEach(field => {
                                field.memberIds = field.members.map(m => m.id) ?? [];
                                obj[field.crossKey] = field
                            });
                            plan.schedule = obj;

                            var obj2 = {};
                            plan.staff.forEach(field => {
                                field.memberIds = field.members.map(m => m.id) ?? [];
                                obj2[field.crossKey] = field
                            });
                            plan.staff = obj2;

                            var obj3 = {};
                            plan.attending.forEach(field => {
                                field.memberIds = field.members.map(m => m.id) ?? [];
                                obj3[field.crossKey] = field
                            });
                            plan.attending = obj3;
                        }

                        data?.future?.forEach((event) => {
                            event.id = uuidv4(); // need this for EventMatrix to identify event separately..
                            parsePlan(event);
                        });
                        data?.events?.forEach((event) => {
                            parsePlan(event);
                        });

                        config.debug && console.debug(`GET Event Plans in`, new Date() - startTime, `ms (id: ${templateId})`, data);
                        resolve(data);
                    }
                    else {
                        reject(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    reject(err)
                });
        });
    }

    static getPlaceSearch(searchStr) {
        var headers = new Headers();
        headers.set('Access-Control-Allow-Origin', '*');

        return new Promise(async (resolve, reject) => {
            fetch(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${searchStr}&key=AIzaSyBjSXgk9L5PlmJdFW4RKqmD05WmEj_SZ_k`, {
                cross: true,
                headers: headers,
                method: 'GET'
            })
                .then(async res => {
                    if (res.ok) {
                        var places = await res.json();
                        resolve(places);
                    }
                    else {
                        var text = await res.text();
                        reject(text)
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(null)
                }
                );
        });
    }

    static getPermissions(uid) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/permission${uid ? `?uid=${uid}` : 's'}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Permissions (${uid ? data.email : ''}${uid ? '' : `ALL: ${Object.keys(data).length}`})`, data);
                        resolve(data);
                    }
                    else {
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.error('Failed to get permissions:', err)
                    reject(err)
                });
        })
    }

    static registerUser(uid) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/permissions?uid=${uid}&newUser=true`, {
                cross: true,
                method: 'POST',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        var text = await res.text();
                        throw new Error(`${res.status} ${res.statusText} - ${text}`);
                    }
                })
                .then(async perm => {
                    config.debug && console.debug('POST permissions', perm);
                    return resolve(perm)
                })
                .catch(err => reject(err));
        })
    }

    static getBible(passage, footnotes = false, headings = false) {
        let headers = new Headers();
        headers.set('Authorization', `Token ${config.bibleToken}`);

        return new Promise(async (resolve, reject) => {
            fetch(`${config.bibleEndpoint}/passage/text/
				?q=${passage}
				&include-footnotes=${footnotes}
				&include-short-copyright=false
				&include-headings=${headings}`, {
                cross: true,
                method: 'GET',
                headers: headers,
            })
                .then(async res => {
                    if (res.ok) {
                        var text = await res.json();

                        var allBibleRefs = [];
                        for (var rawText of text?.passages) {
                            var bibleRef =
                            {
                                passage: null, // 'passage title'
                                lines: [], // { text: 'text goes here', prefix: '\n\t', verseNum 10, isSubtitle: false }
                            };

                            var lastIdx = 0;
                            var lastVerseAdded = undefined;

                            while (lastIdx <= rawText.length - 1) {
                                var searchStr = '\n\n';
                                var newLineIdx = rawText.indexOf(searchStr, lastIdx);

                                var line = rawText.substring(lastIdx, newLineIdx)
                                lastIdx = newLineIdx + searchStr.length

                                if (line.length > 0) {
                                    if (!bibleRef.passage) {
                                        // passage title
                                        bibleRef.passage = line.trim();
                                    }
                                    else {
                                        var verseCount = (line.match(/\[\d+\]/g) || []).length;
                                        // wild verse is verse that belongs to another line and ends in ':'
                                        var isWildVerse = line.substring(line.length - 1, line.length) === ':';
                                        if (verseCount === 0 && !isWildVerse) {
                                            // subtitle
                                            var newText =
                                            {
                                                text: line.trim(),
                                                verseNum: null,
                                                isSubtitle: true,
                                            };
                                            bibleRef.lines.push(newText);
                                        }
                                        else {
                                            // contains verses
                                            var firstInGroup = true;
                                            var verseArr = line.split('[');
                                            for (var verseLine of verseArr) {
                                                var trimmed = verseLine.trim();

                                                if (trimmed.length > 0) {
                                                    var hasVerseNumber = (new RegExp(/\d+\]/g)).test(verseLine)
                                                    if (hasVerseNumber) {
                                                        var verse = verseLine.split('] ')[1];
                                                        var verseNum = parseInt(verseLine.split('] ')[0].trim());
                                                        // used for continuation of verses on next line.. see else statement.. 
                                                        lastVerseAdded = verseNum;

                                                        var verseText =
                                                        {
                                                            text: verse,
                                                            prefix: firstInGroup ? `\n\t` : '',
                                                            verseNum: verseNum,
                                                            isSubtitle: false,
                                                        };

                                                        bibleRef.lines.push(verseText);
                                                    }
                                                    else {
                                                        // belongs to prev verse on previous line..
                                                        // eslint-disable-next-line no-loop-func
                                                        var lastVerse = bibleRef?.lines?.filter(f => f.verseNum === lastVerseAdded)[0];
                                                        lastVerse.text += `\n\t${trimmed}`;
                                                    }

                                                    if (firstInGroup)
                                                        firstInGroup = false;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            allBibleRefs.push(bibleRef);
                        }

                        resolve(allBibleRefs);
                    }
                    else {
                        var error = await res.text();
                        reject(error)
                    }
                })
                .catch(err => reject(err));
        });
    }

    static getStats(start, end) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/stats?start=${JSON.stringify(start)}&end=${JSON.stringify(end)}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Stats`);

                        data.logins = data.logins?.map(record => {
                            return {
                                memberId: record.memberId,
                                date: parseJSON(record.date),
                            }
                        });

                        data.creditUsuage = data.creditUsuage?.map(record => {
                            return {
                                ...record,
                                date: parseJSON(record.date),
                            }
                        })
                        resolve(data);
                    }
                    else {
                        resolve(null)
                    }
                })
                .catch(err => reject(err));
        })
    }

    static getCredits() {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/credits`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Credits`);
                        resolve(data);
                    }
                    else {
                        resolve(null)
                    }
                })
                .catch(err => reject(err));
        })
    }

    static getVersion() {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        var version = await res.text();
                        resolve(version);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(err => console.error(err));
        });
    }

    static keyEventFields = (event) => {
        event?.schedule?.forEach(f => {
            f.isScheduleField = true;
        });
        event?.staff?.forEach(f => {
            f.isStaffField = true;
        });
        event?.attending?.forEach(f => {
            f.isAttendingField = true;
        });
    }

    static searchEvents = async (start, end, name, orderBy, memberId, limit = null) => {
        return new Promise(async (resolve, reject) => {
            var endpoint = `${config.endpoint}/searchEvents?dto=full`;
            if (start) {
                var startStr = JSON.stringify(start);
                endpoint += `&start=${startStr}`;
            }
            if (end) {
                var endStr = JSON.stringify(end);
                endpoint += `&end=${endStr}`;
            }

            if (start || end)
                endpoint += `&orderByDate=${orderBy}`;

            if (name)
                endpoint += `&name=${name}`;

            if (limit)
                endpoint += `&limit=${limit}`;

            if (memberId)
                endpoint += `&memberId=${memberId}`;

            fetch(endpoint, {
                method: 'GET',
                headers: await api.getRequestHeaders()
            })
                .then(async (res) => {
                    if (res.ok) return res.json();

                    var text = await res.text();
                    reject(`${res.status} ${res.statusText} - ${text}`);
                })
                .then(async events => {
                    events = await Promise.all(events.map(async e => {
                        await this.parseEvent(e);
                        e.startStr = isValid(e.start) ? format(e.start, 'MM/dd/yy h:mm aa') : '';
                        return e;
                    }));

                    const sortedEvents = events.sort((a, b) => {
                        return a.start > b.start;
                    });

                    config.debug && console.debug(`GET Search (${events.length}) Events`, sortedEvents);
                    resolve(sortedEvents)
                })
                .catch(error => {
                    console.error(error);
                    reject(error.message);
                });
        });
    }

    static logRemote = async (msg, data = {}) => {
        if (config.logRemote) {
            await this.sendRemoteLog(msg, data);
        }
    }

    static sendRemoteLog(msg, data = {}) {
        return new Promise(async (resolve, reject) => {
            const auth = authCache.GetAuth();
            if (auth) return resolve();
            var allData = { auth };
            Object.keys(data).forEach((key) => {
                allData[key] = data[key];
            });

            var fullMsg = `${auth?.fullname ?? 'Anonymous User'} ${msg}`;
            fetch(`${config.endpoint}/log`, {
                cross: true,
                method: 'POST',
                body: JSON.stringify({ msg: fullMsg, data: allData })
            })
                .then(res => resolve())
                .catch(err => {
                    console.error(err);
                    resolve();
                });
        });
    }

    static getMemberEvents(memberId) {
        return new Promise(async (resolve) => {
            fetch(`${config.endpoint}/memberEvents?id=${memberId}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        const memberEvents = await res.json();
                        const events = memberEvents.events?.map((e) => {
                            e.start_date = parseJSON(e.start);
                            delete e.start;
                            e.end_date = parseJSON(e.end);
                            delete e.end;
                            e.displayName = e.name;
                            delete e.name;
                            e.member = e.fields.length > 0 ? e.fields[0]?.members[0] : null;
                            return e;
                        });

                        config.debug && console.debug(`GET MemberEvents (${events?.length})`);
                        resolve(events);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(err => console.error(err));
        });
    }

    static getMonthlyEvents(month, year) {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/monthlyEvents?month=${month}&year=${year}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async (res) => {
                    if (res.ok) {
                        const montlyEvents = await res.json();
                        config.debug && console.debug(`GET monthlyEvents in`, new Date() - start, `ms (year: ${year}, month: ${month})`);

                        montlyEvents?.events?.forEach((e) => {
                            e.start = parseJSON(e.start);
                            e.end = parseJSON(e.end);
                        });
                        resolve(montlyEvents);
                    }
                    else {
                        var text = await res.text();
                        reject(`${res.status} ${res.statusText} - ${text}`);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static getBlockout(id) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/blockout?id=${id}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Blockout`, data)

                        utils.restoreBlockout(data);

                        resolve(data);
                    }
                    else {
                        reject(res.status)
                    }
                })
                .catch(err => reject(err));
        });
    }

    static getBlockouts(memberId) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/blockouts?memberId=${memberId}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        var data = await res.json();
                        config.debug && console.debug(`GET Blockouts for ${memberId}`);

                        data?.blockouts?.forEach((b) => {
                            utils.restoreBlockout(b);
                        });

                        data.blockouts = data?.blockouts?.filter((b) => {
                            var isFuture = b.nextEnd > new Date();
                            var isRepeatInFuture = b.repeat && b.until > new Date();
                            var isRepeat = b.repeat && b.until === undefined;

                            return isFuture || (isRepeat || isRepeatInFuture);
                        });
                        data.blockouts = data?.blockouts?.sort((a, b) => b.nextStart - a.nextStart);
                        resolve(data?.blockouts);
                    }
                    else if (res.status === 404) {
                        resolve([]);
                    }
                    else {
                        reject(res.status)
                    }
                })
                .catch(err => reject(err));
        });
    }

    static getPost = (id) => {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/post?id=${id}`,
                {
                    cross: true,
                    method: 'GET',
                    headers: await this.getRequestHeaders(),
                })
                .then(async res => {
                    config.debug && console.debug(`GET Post in`, new Date() - start, `ms (id: ${id})`);
                    if (res.ok) {
                        const post = await res.json();
                        post.scheduledDate = post.scheduledDate ? parseJSON(post.scheduledDate) : undefined;
                        post.sentDate = post.sentDate ? parseJSON(post.sentDate) : undefined;
                        post.created = post.created ? parseJSON(post.created) : undefined;
                        post.modified = post.modified ? parseJSON(post.modified) : undefined;

                        return resolve(post);
                    }
                    else {
                        var errorMsg = `Failed to get post ${res.status}`;
                        return reject(errorMsg);
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(err)
                });
        });
    }

    static getAllPosts = () => {
        return new Promise(async (resolve, reject) => {
            const start = new Date();
            fetch(`${config.endpoint}/posts`,
                {
                    cross: true,
                    method: 'GET',
                    headers: await this.getRequestHeaders(),
                })
                .then(async res => {
                    config.debug && console.debug(`GET all Posts in`, new Date() - start, `ms`);
                    if (res.ok) {
                        const data = await res.json();
                        data?.forEach((post) => {
                            post.scheduledDate = post.scheduledDate ? parseJSON(post.scheduledDate) : undefined;
                            post.sentDate = post.sentDate ? parseJSON(post.sentDate) : undefined;
                            post.created = post.created ? parseJSON(post.created) : undefined;
                            post.modified = post.modified ? parseJSON(post.modified) : undefined;
                        });
                        return resolve(data);
                    }
                    else {
                        var errorMsg = `Failed to get all posts ${res.status}`;
                        return reject(errorMsg);
                    }
                })
                .catch(err => {
                    console.error(err)
                    resolve(err)
                });
        });
    }

    static getGroupUpcomingEvents(groupId, start, limit = 5) {
        return new Promise(async (resolve, reject) => {
            var startStr = JSON.stringify(start);
            fetch(`${config.endpoint}/group-upcoming-events?id=${groupId}&start=${startStr}&limit=${limit}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        const data = await res.json();

                        data?.forEach(e => {
                            e.start = parseJSON(e.start);
                            e.end = parseJSON(e.end);
                        });

                        config.debug && console.debug(`GET Upcoming Group Events for ${groupId}`);
                        resolve(data);
                    }
                    else {
                        console.error(`${res.status} ${res.statusText}`);
                        resolve([]);
                    }
                })
                .catch(err => {
                    console.error('Failed to get upcoming group events', err);
                    resolve([]);
                });
        });
    }

    static getGroupPastEvents(groupId, end, limit = 5) {
        return new Promise(async (resolve, reject) => {
            var endStr = JSON.stringify(end);
            fetch(`${config.endpoint}/group-past-events?id=${groupId}&end=${endStr}&limit=${limit}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        const data = await res.json();
                        data?.forEach(e => {
                            e.start = parseJSON(e.start);
                            e.end = parseJSON(e.end);
                        });

                        config.debug && console.debug(`GET Past Group Events for ${groupId}`);
                        resolve(data);
                    }
                    else {
                        console.error(`${res.status} ${res.statusText}`);
                        resolve([]);
                    }
                })
                .catch(err => {
                    console.error('Failed to get past group events', err);
                    resolve([])
                });
        });
    }

    static deletePicture(id, repo) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/picture?id=${id}&repo=${repo}`, {
                cross: true,
                method: 'DELETE',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        resolve();
                    }
                    else {
                        reject(`${res.status} ${res.statusText}`);
                    }
                })
                .catch(err => {
                    console.error('Failed to delete picture', err);
                    reject(err)
                });
        });
    }

    static buildEvent(templateId, start) {
        return new Promise(async (resolve, reject) => {
            fetch(`${config.endpoint}/buildEvent?templateId=${templateId}&start=${JSON.stringify(start)}`, {
                cross: true,
                method: 'GET',
                headers: await this.getRequestHeaders(),
            })
                .then(async res => {
                    if (res.ok) {
                        const data = await res.json();
                        const event = await this.parseEvent(data);

                        resolve(event);
                    }
                    else {
                        var error = await res.text();
                        reject(`${res.status} ${res.statusText}: ${error}`);
                    }
                })
                .catch(err => {
                    console.error('Failed to build event on remote server', err);
                    reject(err)
                });
        });
    }
}
