import firebase from '../utils/firebase'
import queryString from 'query-string';
import { format, isValid, addDays, addWeeks, addMonths, addYears, parseJSON } from 'date-fns';

const cyrillicPattern = /^[\u0400-\u04FF]+$/;

export default class utils {
    static sleep = async (ms) => {
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }
    static num_lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];

    static async getLatestUserToken() {
        var user = await firebase.auth();
        var userIdToken = await user.currentUser?.getIdToken();

        if (userIdToken === undefined) return '';
        return userIdToken;
    }

    static async getRequestHeaders() {
        let headers = new Headers();
        let token = await this.getLatestUserToken();
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', token);
        return headers;
    }

    static updateSearchParams(props, kvps) {
        if (!props || !kvps) return;

        var params = queryString.parse(props.router.location.search);
        kvps.forEach(kvp => params[kvp.key] = kvp.value instanceof Date
            ? isValid(kvp.value) ? format(kvp.value, 'MM dd yyyy') : ''
            : kvp.value);

        if (props?.setSearchParams) props?.setSearchParams(params)
    }

    static wait(timeMs) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), timeMs);
        });
    }

    static sortMembersByConfirmation = (members) => {
        // sort participants by field.. confirmed, notified, decline, un-notified..
        members.forEach(m => {
            if (m.member !== undefined) {
                m.confirmed = m.member.confirmed;
                m.notified_sms_dt = m.member.notified_sms_dt;
                m.notified_email_dt = m.member.notified_email_dt;
            }

            if (m.confirmed) {
                m.order = 1;
                return;
            }
            if (m.notified_sms_dt !== undefined || m.notified_email_dt !== undefined) {
                m.order = 2;
                return;
            }
            if (m.confirmed === false) {
                m.order = 3;
                return;
            }
            if (!m.confirmed) {
                m.order = 4;
                return;
            }
        });

        var sorted = members.sort(function (a, b) {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
        });

        return sorted;
    }

    static sortMembersByAlpha = (members) => {
        var sorted = members.sort(function (a, b) {
            if (a.fullname < b.fullname) { return -1; }
            if (a.fullname > b.fullname) { return 1; }
            return 0;
        });

        return sorted;
    }

    static numberFormatter(num, digits) {
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = utils.num_lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    static getEventName(event) {
        if (event) {
            var shortName = event?.displayName;
            if (event?.start_date) {
                shortName += ' ' + format(event?.start_date, 'MM/dd/yyyy');
            }

            return shortName;
        }

        return 'Undefined Event';
    }

    static containsCyrillic(text) {
        for (const char of text) {
            if (cyrillicPattern.test(char))
                return true;
        }

        return false;
    }

    static deepClone(obj) {
        return JSON.parse(JSON.stringify(obj))
    }

    static restoreBlockout = (b) => {
        if (b.start) b.start = parseJSON(b.start);
        if (b.end) b.end = parseJSON(b.end);
        if (b.until) b.until = parseJSON(b.until);

        if (b.repeat && new Date() > b.start) {
            if (b.unit === 'day') {
                b.nextStart = addDays(b.start, b.frequency);
                b.nextEnd = addDays(b.end, b.frequency);
            }
            else if (b.unit === 'week') {
                b.nextStart = addWeeks(b.start, b.frequency);
                b.nextEnd = addWeeks(b.end, b.frequency);
            }
            else if (b.unit === 'month') {
                b.nextStart = addMonths(b.start, b.frequency);
                b.nextEnd = addMonths(b.end, b.frequency);
            }
            else if (b.unit === 'year') {
                b.nextStart = addYears(b.start, b.frequency);
                b.nextEnd = addYears(b.end, b.frequency);
            }
        }
        else {
            b.nextStart = b.start;
            b.nextEnd = b.end;
        }

        return b;
    }


    static getLargePicture(url) {
        if (url) {
            if (url.includes('_small.png')) {
                return url.replace('_small.png', '_large.png');
            }
            if (url.includes('_medium.png')) {
                return url.replace('_medium.png', '_large.png');
            }
            if (url.includes('_large.png')) {
                return url;
            }
        }
        return null;
    }

    static getMediumPicture(url) {
        if (url) {
            if (url.includes('_small.png')) {
                return url.replace('_small.png', '_medium.png');
            }
            if (url.includes('_medium.png')) {
                return url;
            }
            if (url.includes('_large.png')) {
                return url.replace('_large.png', '_medium.png');
            }
        }
        return null;
    }

    static getSmallPicture(url) {
        if (url) {
            if (url.includes('_small.png')) {
                return url;
            }
            if (url.includes('_medium.png')) {
                return url.replace('_medium.png', '_small.png');
            }
            if (url.includes('_large.png')) {
                return url.replace('_large.png', '_small.png');
            }
        }
        return null;
    }
}