import React, { useState } from 'react';
import { Button, Divider, Dropdown, Header, Icon, Image, Modal, Placeholder, Popup, Table } from 'semantic-ui-react';
import { format, isValid, parseJSON, set, getYear } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import withRouter from '../utils/withRouter';
import colors from '../utils/colors';
import StatusBar from './StatusBar';
import api from '../utils/api';
import moment from 'moment';
import LinkedText from './LinkedText';
import HyperLink from './HyperLink';
import memberCache from '../caches/memberCache';
import { isDesktop, isMobile } from 'react-device-detect';
import utils from '../utils/utils';
import config from '../utils/config';
import TextEntryForm from './TextEntryForm';
import Avatar from './Avatar';
import SmoothModal from '../popups/components/SmoothModal';
import { EventVisibility } from '../utils/permUtils';
import CommentsSectionAsync from './CommentsSectionAsync';
import authCache from '../caches/authCache';
import ReactGA from "react-ga4";
import ConfirmationPopup from '../popups/ConfirmationPopup';
import EmptySegment from './EmptySegment';

class ScheduleView extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            loading: true,
            error: null,
            event: null,
            showDetails: false,
            eventId: null,
            memberId: this.props.memberId,
            showAllParticipants: this.props.showAllParticipants,
            geoLocation: null,
            members: {},
            showMap: false,
            attendingModal: null,
            auth: null,
            showSchedule: true,
            showStaff: true,
            showAttending: true,
            showLocation: true,
            isRussian: false,
            unconfirmedFields: [],
            memberFields: [],
            confirmed: false,
            declineEvent: false,
            declineReason: null,
            permChecker: null,
            attendingMembers: [],
            copiedLink: false,
            confirmation: null,
            canConfirm: true,
            showConfirmation: false,
            notFound: false,
            forceConfirm: false,
            multiFields: false,
        }

        this.attendeesContainer = React.createRef();
        this.topPage = React.createRef();
    }

    componentDidMount() {
        this.onAuthRetrieved(authCache.GetAuth());
        authCache.subscribeOnLoad(this.onAuthRetrieved);

        this.reload(this.props.memberId ?? authCache.GetAuth()?.id);
    }

    onAuthRetrieved = (auth) => {
        this.setState({ auth }, () => {
            if (!this.state.memberId) {
                this.reload(this.state.auth?.id);
            }
        });
    }

    onAuthTimeout = () => config.debug && console.debug('Giving up trying on waiting for permissions..');

    componentDidUpdate = (prevProps) => {
        if (prevProps.eventId !== this.props.eventId || prevProps.shortEventId !== this.props.shortEventId) {
            this.reload(this.props.memberId, this.props.shortEventId);
        }
    }

    componentWillUnmount = () => {
        authCache.unsubscribeOnLoad(this.onAuthRetrieved);
    }

    reload = async (memberId) => {
        if (!memberId && !this.props.shortEventId && !this.props.eventId) return;

        const members = await memberCache.GetMembersDict();
        this.setState({ loading: true, members, memberId }, () => {
            if (this.props.event) {
                const isRussian = utils.containsCyrillic(this.props.event?.name);
                this.setState({ event: this.props.event, loading: false, isRussian });
            }
            else {
                this.getEvent(this.props.eventId, memberId, this.props.shortEventId);
            }
        })
    }

    getEvent = async (eventId, memberId, shortEventId) => {
        this.setState({ loading: true, error: null, showDetails: false, eventId }, async () => {
            try {
                var eventIdOverride = eventId;
                var event = undefined;
                var memberIdOverride = memberId;

                if (shortEventId) {
                    const data = await api.getEventViaShortId(shortEventId);

                    event = data.event;
                    eventIdOverride = event?.id;
                    memberIdOverride = data.memberId;
                } else {
                    event = await api.getEvent(eventId, memberId);
                }

                this.setState({ eventId: eventIdOverride, memberId: memberIdOverride }, async () => {
                    if (event !== null) {
                        const isRussian = utils.containsCyrillic(event?.name ?? '');
                        this.setState({ isRussian, loading: false });

                        if (this.props.onEventLoaded) this.props.onEventLoaded(event);

                        config.debug && console.log(event);
                        await this.updateEvent(event, memberIdOverride);

                        ReactGA.event({ category: 'ScheduleView', action: 'View Event' });
                    }
                    else {
                        this.setState({ error: 'Event cannot be found', loading: false });
                    }
                })
            }
            catch (res) {

                // not found
                console.error(res);
                var error = await res.text();
                this.setState({ error: error, loading: false, notFound: true });
            }
        });
    }

    updateEvent = async (event, memberId) => {
        if (memberId) {
            const memberFields = this.getAllMemberRoles(event, memberId);

            const acceptedFields = memberFields?.filter(f => f.members.some(m => m.confirmed === true));
            const declinedFields = memberFields?.filter(f => f.members.some(m => m.confirmed === false));
            const unconfirmedFields = memberFields?.filter(f => f.members.some(m => m.confirmed === undefined));

            var canConfirm = (event.vis !== EventVisibility.Restricted || (event.vis === EventVisibility.Restricted && memberFields.length > 0))
                && !this.props.isGuest;

            var isConfirmed = acceptedFields?.length > 0 && acceptedFields?.length === memberFields?.length;
            var isDeclined = declinedFields?.length > 0 && declinedFields?.length === memberFields?.length;
            var responded = isConfirmed || isDeclined;

            this.setState({
                canConfirm,
                memberFields,
                showConfirmation: canConfirm,
                unconfirmedFields,
                multiFields: memberFields?.length > 1,
                confirmation: responded ? isConfirmed : null
            });
        }

        var attendingMembers = [];
        const attendingField = event?.attending?.length > 0 ? event?.attending[0] : null;
        if (attendingField) {
            for (var member of attendingField?.members) {
                const fullMember = await memberCache.GetSingleMember(member?.id);
                member.avatar = fullMember?.avatar;
                attendingMembers.push(member);
            }

            attendingMembers = attendingMembers.sort((a, b) => {
                if (a.confirmed === true) return -1;
                return 1;
            });
        }

        this.setState({
            loading: false,
            event,
            notFound: false,
            attendingMembers: attendingMembers
        });
    }

    getAllMemberRoles = (event, memberId) => {
        var allRoles = [];

        function getMemberFields(fields, memberId) {
            const roles = [];
            for (var field of fields ?? []) {
                if (field.members?.find(m => m.id === memberId)) {
                    var copy = { ...field };
                    if (copy.field === 'Attending') copy.field = "Attendance";
                    copy.members = copy.members.filter(m => m.id === memberId)
                    roles.push(copy);
                }
            }
            return roles;
        }

        allRoles = allRoles.concat(getMemberFields(event.schedule, memberId));
        allRoles = allRoles.concat(getMemberFields(event.staff, memberId));
        allRoles = allRoles.concat(getMemberFields(event.attending, memberId));

        return allRoles;
    }

    sendConfirmation = async (acceptKeys, declineKeys, comment = undefined) => {
        const memberId = this.state.memberId;
        const eventId = this.state.event.id;

        var clientError = '';

        if (!memberId) clientError = `Invalid memberId: ${memberId}`;
        if (!eventId) clientError = `Invalid eventId: ${eventId}`;

        if (clientError) {
            this.setState({
                error: clientError,
                submitLoading: false,
                acceptLoading: false,
                declineLoading: false,
                forceConfirm: false,
            });

            this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        this.setState({ acceptLoading: Object.keys(acceptKeys)?.length > 0, declineLoading: Object.keys(declineKeys)?.length > 0, error: null }, async () => {
            fetch(`${config.endpoint}/confirm`, {
                crossDomain: true,
                method: 'POST',
                headers: await api.getRequestHeaders(),
                body: JSON.stringify(
                    {
                        eventId: eventId,
                        memberId: memberId,
                        comment,
                        acceptKeys,
                        declineKeys,
                    }
                ),
            })
                .then(async res => {
                    if (res.ok) {
                        const updated = await res.json();
                        await api.parseEvent(updated);

                        this.setState({
                            success: this.state.isRussian ? 'Благодарим Вас за подтверждение' : `Thank you for confirming`,
                            acceptLoading: false,
                            declineLoading: false,
                            declineEvent: false,
                            confirmed: true,
                            loading: true,
                            forceConfirm: false,
                        }, () => {
                            const eventCopy = { ...this.state.event };

                            eventCopy.schedule = updated.schedule;
                            eventCopy.staff = updated.staff;
                            eventCopy.attending = updated.attending;

                            this.setState({ event: eventCopy, loading: false });

                            this.updateEvent(eventCopy, this.state.memberId)
                        });
                    }
                    else {
                        var text = await res.text();
                        this.setState({
                            error: `${res.status} ${res.statusText} - ${text}`,
                            submitLoading: false,
                            acceptLoading: false,
                            declineLoading: false,
                            forceConfirm: false,
                        });

                        this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                    }
                })
                .catch(error => {
                    this.setState({
                        error,
                        submitLoading: false,
                        acceptLoading: false,
                        declineLoading: false,
                        forceConfirm: false,
                    });
                    this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                });
        });
    }

    closeConfirmation = () => this.setState({ showConfirmation: false });

    hasFields = (keysDict) => {
        if (keysDict.schedule?.length > 0) return true;
        if (keysDict.staff?.length > 0) return true;
        if (keysDict.attending?.length > 0) return true;
        return false;
    }

    render() {
        const {
            loading,
            error,
            event,
            showDetails,
            showAllParticipants,
            showMap,
            auth,
            showSchedule,
            showStaff,
            showAttending,
            showLocation,
            attendingModal,
            members,
            isRussian,
            acceptLoading,
            declineLoading,
            declineEvent,
            declineReason,
            attendingMembers,
            confirmation,
            memberId,
            canConfirm,
            showConfirmation,
            unconfirmedFields,
            memberFields,
            multiFields,
            notFound,
            forceConfirm
        } = this.state;

        const {
            hideHeader,
            headerImg,
            hideMembers,
            isGuest,
            isConference
        } = this.props;

        var startDate = new Date(event?.start);
        var endDate = new Date(event?.end);
        var endsDiffDays = startDate.toDateString() !== endDate.toDateString();
        var sameYear = getYear(new Date()) === getYear(startDate);

        var startDateStr = isValid(startDate)
            ? isRussian
                ? format(startDate, 'd MMMM ', { locale: ru })
                : format(startDate, `MMMM do${!sameYear ? ', yyyy' : ''}`, { locale: enUS })
            : '';

        var endDateStr = isValid(endDate) ? format(endDate, 'MMMM do, yyyy', { locale: isRussian ? ru : enUS }) : '';

        var startTimeStr = isValid(startDate) ? format(startDate, 'h:mmaaa') : '';
        var endTimeStr = isValid(endDate) ? format(endDate, 'h:mmaaa') : '';

        var updatedBy = members != null ? members[event?.modified_by] : undefined;
        var owner = members != null ? members[event?.owner] : undefined;
        const canViewReasons = event?.owner === auth?.id || auth?.admin;

        return (
            <div>
                {!notFound && <StatusBar style={{ marginBottom: 20 }} error={error} />}
                <div>
                    {loading ?
                        <Placeholder fluid>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph></Placeholder.Paragraph>
                            {[0, 1, 2, 3, 4].map(() => {
                                return (
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                )
                            })}
                        </Placeholder>
                        :

                        <div ref={this.topPage}>
                            {notFound ?
                                <EmptySegment
                                    title='404 - Not Found'
                                    description="Event not found. The event you are looking for may been deleted"
                                    icon='calendar delete'
                                    segment
                                />
                                :
                                <div>

                                    {/* show popup only on dedicated event page, not in event popup.. */}
                                    {event && (forceConfirm || (unconfirmedFields?.length > 0 && (this.props.router?.location?.pathname === '/event' || this.props.router?.location?.pathname === '/e'))) &&
                                        <ConfirmationPopup
                                            memberId={memberId}
                                            open={showConfirmation}
                                            fields={memberFields}
                                            event={event}
                                            dateStr={`${startDateStr}`}
                                            timeStr={endsDiffDays ? startTimeStr : `${startTimeStr} - ${endTimeStr}`}
                                            onClose={this.closeConfirmation}
                                            onSubmit={(acceptKeys, declineKeys, comment) => {
                                                this.sendConfirmation(acceptKeys, declineKeys, comment)
                                            }
                                            }
                                        />
                                    }
                                    {/* } */}

                                    {event?.picture && !headerImg &&
                                        <div style={{ marginLeft: -10, marginRight: -10, marginTop: isMobile ? 45 : -20 }}>
                                            <Image
                                                fluid
                                                centered
                                                style={{ width: '100%', objectFit: 'cover', borderRadius: 5, maxHeight: isMobile ? (window.screen.width - 40) * (9 / 16) : 360 }}
                                                src={isDesktop ? utils.getLargePicture(event.picture) : utils.getMediumPicture(event.picture)}
                                                size='medium'
                                            />
                                        </div>
                                    }

                                    {headerImg &&
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Image src={headerImg} style={{ borderRadius: 20, width: '100%', marginBottom: 0 }} />
                                        </div>
                                    }

                                    {/* {isConference &&
										<div style={{ lineHeight: 1.4, width: '100%', backgroundColor: 'white', padding: 20, borderRadius: 20 }}>
											<ConferenceLogo />
										</div>
									} */}

                                    <div style={{ width: '100%', display: 'flex', marginTop: 20, marginBottom: 8 }}>
                                        {/* {auth?.admin && this.props.adminView && <Icon name={visIconName} style={{color: visIconColor, fontSize: 18}}/>} */}
                                        <div style={{ width: '100%', fontSize: 26, fontWeight: 600, paddingTop: 5, lineHeight: 1.4 }}>
                                            {event?.name}
                                        </div>

                                        <div style={{ marginTop: 'auto', marginBottom: 'auto', width: 150, display: 'flex', justifyContent: 'end' }}>
                                            <div>
                                                {auth?.isStaff &&
                                                    <Popup
                                                        trigger={<Icon name='pencil' style={{ color: colors.darkGray, cursor: 'pointer', marginRight: 15 }}
                                                            onClick={() => {
                                                                ReactGA.event({ category: 'ScheduleView', action: 'Edit Event' });
                                                                this.props.router.navigate(`/editor?id=${event?.id}`)
                                                            }
                                                            } />}
                                                        content='Edit Event'
                                                    />
                                                }

                                                {event?.vis === EventVisibility.Public &&
                                                    <Popup
                                                        trigger={<Icon name={'share alternate'} style={{ color: colors.darkGray, cursor: 'pointer', marginRight: 15 }}
                                                            onClick={() => {
                                                                ReactGA.event({ category: 'ScheduleView', action: 'Share Event' });

                                                                var userLink = `${window.location.origin}/event?id=${event?.id}`;
                                                                navigator?.clipboard?.writeText(userLink);

                                                                this.setState({ copiedLink: userLink });
                                                            }}
                                                        />}
                                                        content={'Share Event'}
                                                    />
                                                }

                                                <Popup
                                                    trigger={
                                                        <Dropdown icon='ellipsis vertical' style={{ color: colors.black }} direction='left'>
                                                            <Dropdown.Menu>
                                                                {!hideMembers &&
                                                                    <Dropdown.Item
                                                                        text={`${showAllParticipants ? isRussian ? 'Скрыть участников' : 'Hide participants' : isRussian ? 'Показать участников' : 'Show participants'}`}
                                                                        onClick={() => {
                                                                            this.setState({ showAllParticipants: !showAllParticipants });
                                                                            ReactGA.event({ category: 'ScheduleView', action: 'Toggle participants' });
                                                                        }}
                                                                    />
                                                                }
                                                                {auth?.admin &&
                                                                    <Dropdown.Item
                                                                        text={this.state.showDetails ? `Hide Details` : `Show Details`}
                                                                        onClick={() => {
                                                                            this.setState({ showDetails: !this.state.showDetails })
                                                                            ReactGA.event({ category: 'ScheduleView', action: 'Toggle Details' });
                                                                        }}
                                                                    />
                                                                }
                                                                {auth?.isStaff &&
                                                                    <Dropdown.Item
                                                                        text={`Save as new Event`}
                                                                        onClick={() => {

                                                                            const eventCopy = { ...this.state.event };
                                                                            // for some reason if you try with notify_time populated, React will force refresh of page and state is lost..
                                                                            // I guess moment cannot be send over as a state? not sure..
                                                                            eventCopy.scheduled_notifs?.forEach((n) => {
                                                                                var time = moment(n.notify_time);
                                                                                var date = set(n.notify_date, { hours: time.hours(), minutes: time.minutes(), seconds: time.seconds() });
                                                                                n.notify_date = date;
                                                                                n.notify_time = date;
                                                                            });


                                                                            ReactGA.event({ category: 'ScheduleView', action: 'Copy as Event' });
                                                                            this.props.router.navigate('/editor', { state: { eventCopy: eventCopy } })
                                                                        }}
                                                                    />
                                                                }
                                                                {auth?.isStaff &&
                                                                    <Dropdown.Item
                                                                        text={`Save as new Template`}
                                                                        onClick={() => {
                                                                            const eventCopy = { ...this.state.event };
                                                                            // for some reason if you try with notify_time populated, React will force refresh of page and state is lost..
                                                                            // I guess moment cannot be send over as a state? not sure..
                                                                            eventCopy.scheduled_notifs?.forEach((n) => {
                                                                                var time = moment(n.notify_time);
                                                                                var date = set(n.notify_date, { hours: time.hours(), minutes: time.minutes(), seconds: time.seconds() });
                                                                                n.notify_date = date;
                                                                                n.notify_time = date;
                                                                            });

                                                                            ReactGA.event({ category: 'ScheduleView', action: 'Copy as Template' });
                                                                            this.props.router.navigate('/template', { state: { eventCopy: eventCopy } })
                                                                        }}
                                                                    />
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>}
                                                    style={{ color: colors.darkGray, cursor: 'pointer', marginRight: 15 }}
                                                    content={'More Options'}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {endsDiffDays &&
                                        <div>
                                            <div style={{
                                                marginTop: 3,
                                                fontSize: 16,
                                                fontWeight: 100,
                                                color: colors.darkGray,
                                                lineHeight: 1.5
                                            }}>
                                                {/* <Icon name='calendar outline' style={{ marginLeft: 0, marginRight: 10 }} /> */}
                                                Starts on {startDateStr} - {startTimeStr}
                                            </div>
                                            <div style={{
                                                marginTop: 3,
                                                fontSize: 16,
                                                fontWeight: 100,
                                                color: colors.darkGray,
                                                lineHeight: 1.5
                                            }}>
                                                {/* <Icon name='calendar outline' style={{ marginLeft: 0, marginRight: 10 }} /> */}
                                                Ends at {endDateStr} - {endTimeStr}
                                            </div>
                                        </div>
                                    }

                                    {!endsDiffDays &&
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.darkGray,
                                            lineHeight: 2,
                                        }}>
                                            <div style={{ display: 'inline-block', marginRight: 15, color: colors.accent }}>
                                                <Icon name='calendar outline' style={{ marginRight: 10 }} />
                                                {startDateStr}
                                            </div>
                                            <div style={{ display: 'inline-block', marginRight: 15 }}>
                                                <Icon name='clock outline' style={{ marginRight: 10 }} />
                                                {startTimeStr} - {endTimeStr}
                                            </div>
                                            {!isGuest &&
                                                <div style={{ display: 'inline-block' }}>
                                                    <Icon name='globe' style={{ marginRight: 10 }} />
                                                    {event?.vis
                                                        ? event.vis === 1
                                                            ? 'Public'
                                                            : event.vis === 2
                                                                ? 'Private'
                                                                : event.vis === 2
                                                                    ? 'Hidden'
                                                                    : 'Restricted'
                                                        : 'Unknown'}
                                                </div>
                                            }
                                        </div>
                                    }

                                    {showDetails &&
                                        <div style={{ display: 'flex', marginTop: 7, fontSize: 13, color: colors.darkGray }}>
                                            Created by
                                            <Avatar
                                                memberId={owner?.id}
                                                avatar={owner?.avatar}
                                                tooltip={owner?.fullname ?? 'Not Found'}
                                                style={{ marginTop: -2 }}
                                                suffix={isValid(event?.created) && `on ${format(event?.created, 'EEE. LLL dd, yyyy')} at ${format(event?.created, 'h:mm aa')}`}
                                                router={this.props.router}
                                            />
                                        </div>
                                    }

                                    {showDetails &&

                                        <div style={{ display: 'flex', marginTop: 10, fontSize: 13, color: colors.darkGray }}>
                                            Modified by
                                            <Avatar
                                                memberId={updatedBy?.id}
                                                avatar={updatedBy?.avatar}
                                                tooltip={updatedBy?.fullname ?? 'Not Found'}
                                                style={{ marginTop: -2 }}
                                                suffix={isValid(event?.modified) && `on ${format(event?.modified, 'EEE. LLL dd, yyyy')} at ${format(event?.modified, 'h:mm aa')}`}
                                                router={this.props.router}
                                            />
                                        </div>
                                    }

                                    {canConfirm &&
                                        <div style={{ display: 'flex', marginTop: 20 }}>
                                            <Header style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: 15 }}>
                                                {multiFields ? 'Availability Changed?' : 'Going?'}
                                            </Header>
                                            {memberId ?
                                                <>
                                                    {!multiFields &&
                                                        <Button
                                                            disabled={acceptLoading || declineLoading}
                                                            loading={acceptLoading}
                                                            onClick={() => {
                                                                var acceptKeys = {
                                                                    'schedule': memberFields?.filter(f => f.type === 'schedule')?.map(f => f.crossKey),
                                                                    'staff': memberFields?.filter(f => f.type === 'staff')?.map(f => f.crossKey),
                                                                    'attending': memberFields?.filter(f => f.type === 'attending')?.map(f => f.crossKey),
                                                                };
                                                                // in the event that member is not part of any fields to confirm, but sends confirmation..
                                                                if (!this.hasFields(acceptKeys)) acceptKeys.attending.push('1_Attending');
                                                                this.sendConfirmation(acceptKeys, {})
                                                            }}
                                                            secondary={confirmation === true}
                                                            style={{ marginRight: 10, minWidth: 70 }}
                                                            circular
                                                            compact
                                                        >
                                                            {confirmation === true && <Icon name='check' />}
                                                            Yes
                                                        </Button>
                                                    }
                                                    {!multiFields &&
                                                        <Button
                                                            disabled={acceptLoading || declineLoading}
                                                            loading={declineLoading}
                                                            onClick={() => {
                                                                this.setState({ declineEvent: true })
                                                            }}
                                                            secondary={confirmation === false}
                                                            style={{ marginRight: 10, minWidth: 70 }}
                                                            circular
                                                            compact
                                                        >
                                                            {confirmation === false && <Icon name='close' />}
                                                            {multiFields ? 'No' : ' Not Going'}
                                                        </Button>
                                                    }
                                                    {memberFields?.length > 1 &&
                                                        <Button
                                                            disabled={acceptLoading || declineLoading}
                                                            loading={acceptLoading || declineLoading}
                                                            onClick={() => this.setState({ forceConfirm: true, showConfirmation: true })}
                                                            style={{ marginRight: 10, minWidth: 70 }}
                                                            circular
                                                            compact
                                                        >
                                                            {unconfirmedFields?.length > 0 ? 'Send Response' : 'Edit Response'}
                                                        </Button>
                                                    }
                                                </>
                                                :
                                                <Button
                                                    onClick={() => {
                                                        const prevPathname = this.props.router?.location?.pathname;
                                                        const prevSearch = this.props.router?.location?.search;

                                                        this.props?.router?.navigate('/login', { state: { prevPathname, prevSearch } });
                                                    }
                                                    }
                                                    secondary
                                                    style={{ marginRight: 10, minWidth: 90 }}
                                                    circular
                                                    compact
                                                >
                                                    <Icon name='sign in' />
                                                    Login
                                                </Button>
                                            }
                                        </div>
                                    }

                                    {event?.notes &&
                                        <>
                                            <div style={{ width: '100%', marginTop: 20 }}>
                                                <Divider />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ fontSize: 15, marginTop: 10, color: colors.black }}>
                                                    <LinkedText text={event?.notes} />
                                                </p>
                                            </div>
                                        </>
                                    }

                                    <div>
                                        {!hideHeader && event?.schedule?.length > 0 &&
                                            <Button
                                                style={{ textAlign: 'left', borderRadius: 10, color: colors.darkGray, display: 'flex', marginTop: 30 }}
                                                fluid
                                                onClick={() => this.setState({ showSchedule: !showSchedule })}
                                            >
                                                {isRussian ? 'РАСПИСАНИЕ' : 'SCHEDULE'}
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <Icon name={showSchedule ? 'sort down' : 'sort up'}></Icon>
                                                </div>
                                            </Button>
                                        }

                                        {showSchedule && event?.schedule?.map((i, fieldIndex) => {
                                            return (
                                                <div key={fieldIndex}>

                                                    <SegmentLineItem
                                                        key={fieldIndex}
                                                        timestamp={i.timestamp ? moment(i.timestamp) : null}
                                                        hasTimestamp={i.timestamp ? true : false}
                                                        text={i.field}
                                                        textSize={16}
                                                        textColor={colors.black}
                                                        textWeight={500}
                                                        secondaryText={i.notes}
                                                        secondaryTextColor={colors.darkGray}
                                                        secondaryTextSize={13}
                                                        secondaryTextLinked={true}
                                                        color={colors.lightMidGray}
                                                        style={{ marginBottom: 10, paddingLeft: 20, marginTop: 20 }}
                                                        divider={fieldIndex !== 0}
                                                        router={this.props.router}
                                                    />

                                                    <div>
                                                        {i.declinedMembers?.map((mem, memIndex) => {
                                                            return (
                                                                <SegmentLineItem
                                                                    userSegment
                                                                    avatar={mem?.avatar}
                                                                    memberId={mem?.id}
                                                                    index={memIndex}
                                                                    key={memIndex}
                                                                    hasTimestamp={i.timestamp ? true : false}
                                                                    text={mem?.fullname ?? 'Not Found'}
                                                                    textColor={mem.confirmed ? colors.darkGray : mem.confirmed === false ? colors.darkGray : colors.midGray}
                                                                    confirmedTime={mem.confirmedTime}
                                                                    secondaryText={showDetails && mem.confirmedDetails ? mem.confirmedDetails : null}
                                                                    secondaryTextColor={colors.midGray}
                                                                    secondaryTextSize={12}
                                                                    notified={mem.notif_email_dt || mem.notif_sms_dt}
                                                                    confirmed={false}
                                                                    declinedReason={canViewReasons ? mem.confirmed_reason : null}
                                                                    color={colors.lightGray}
                                                                    historicalDecline
                                                                    style={{ paddingLeft: 35, paddingTop: memIndex === 0 ? 3 : 0 }}
                                                                    router={this.props.router}
                                                                    onTextDoubleClick={() => {
                                                                        if (auth?.admin) {
                                                                            var userLink = `${window.location.origin}/event?id=${event?.id}&member=${mem.id}`;
                                                                            navigator?.clipboard?.writeText(userLink);
                                                                        }
                                                                    }}
                                                                />
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                    {/* show only members the participant list */}
                                                    {!hideMembers && i.members.map((mem, memIndex) => {
                                                        if (showAllParticipants || mem.id === memberId) {
                                                            return (
                                                                <SegmentLineItem
                                                                    userSegment
                                                                    avatar={mem?.avatar}
                                                                    memberId={mem?.id}
                                                                    index={memIndex}
                                                                    key={memIndex}
                                                                    hasTimestamp={i.timestamp ? true : false}
                                                                    text={mem?.fullname ?? 'Not Found'}
                                                                    textColor={mem.confirmed ? colors.darkGray : mem.confirmed === false ? colors.darkGray : colors.midGray}
                                                                    confirmedTime={mem.confirmedTime}
                                                                    secondaryText={showDetails && mem.confirmedDetails ? mem.confirmedDetails : null}
                                                                    secondaryTextColor={colors.midGray}
                                                                    secondaryTextSize={12}
                                                                    notified={mem.notif_email_dt || mem.notif_sms_dt}
                                                                    confirmed={mem.confirmed}
                                                                    declinedReason={canViewReasons ? mem.confirmed_reason : null}
                                                                    color={colors.lightGray}
                                                                    style={{ paddingLeft: 35, paddingTop: memIndex === 0 ? 3 : 0 }}
                                                                    router={this.props.router}
                                                                    onTextDoubleClick={() => {
                                                                        if (auth?.admin) {
                                                                            var userLink = `${window.location.origin}/event?id=${event?.id}&member=${mem.id}`;
                                                                            navigator?.clipboard?.writeText(userLink);
                                                                        }
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            );
                                        })
                                        }

                                        {!isGuest && !hideHeader && event?.staff?.length > 0 &&
                                            <Button
                                                style={{ textAlign: 'left', borderRadius: 10, color: colors.darkGray, display: 'flex', marginTop: 30 }}
                                                fluid
                                                onClick={() => this.setState({ showStaff: !showStaff })}
                                            >
                                                {isRussian ? 'ПЕРСОНАЛ' : 'STAFF'}
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <Icon name={showStaff ? 'sort down' : 'sort up'}></Icon>
                                                </div>
                                            </Button>
                                        }

                                        {!isGuest && showStaff && event?.staff?.map((i, fieldIndex) => {
                                            return (
                                                <div key={fieldIndex}>

                                                    <SegmentLineItem
                                                        key={fieldIndex}
                                                        text={i.field}
                                                        textSize={16}
                                                        textColor={colors.black}
                                                        textWeight={500}
                                                        secondaryText={i.notes}
                                                        secondaryTextColor={colors.darkGray}
                                                        secondaryTextSize={13}
                                                        secondaryTextLinked={true}
                                                        color={colors.lightMidGray}
                                                        style={{ marginBottom: 10, paddingLeft: 20, marginTop: 20 }}
                                                        divider={fieldIndex !== 0}
                                                        router={this.props.router}
                                                    />

                                                    <div>
                                                        {i.declinedMembers?.map((mem, memIndex) => {
                                                            return (
                                                                <SegmentLineItem
                                                                    userSegment
                                                                    avatar={mem?.avatar}
                                                                    memberId={mem?.id}
                                                                    index={memIndex}
                                                                    key={memIndex}
                                                                    hasTimestamp={i.timestamp ? true : false}
                                                                    text={mem?.fullname ?? 'Not Found'}
                                                                    textColor={mem.confirmed ? colors.darkGray : mem.confirmed === false ? colors.darkGray : colors.midGray}
                                                                    confirmedTime={mem.confirmedTime}
                                                                    secondaryText={showDetails && mem.confirmedDetails ? mem.confirmedDetails : null}
                                                                    secondaryTextColor={colors.midGray}
                                                                    secondaryTextSize={12}
                                                                    notified={mem.notif_email_dt || mem.notif_sms_dt}
                                                                    confirmed={false}
                                                                    declinedReason={canViewReasons ? mem.confirmed_reason : null}
                                                                    color={colors.lightGray}
                                                                    style={{ paddingLeft: 35, paddingTop: memIndex === 0 ? 3 : 0 }}
                                                                    historicalDecline
                                                                    router={this.props.router}
                                                                    onTextDoubleClick={() => {
                                                                        if (auth?.admin) {
                                                                            var userLink = `${window.location.origin}/event?id=${event?.id}&member=${mem.id}`;
                                                                            navigator?.clipboard?.writeText(userLink);
                                                                        }
                                                                    }}
                                                                />
                                                            );
                                                        })
                                                        }
                                                    </div>

                                                    {/* show only members the participant list */}
                                                    {!hideMembers && i.members.map((mem, memIndex) => {
                                                        if (showAllParticipants || mem.id === memberId) {
                                                            return (
                                                                <SegmentLineItem
                                                                    userSegment
                                                                    avatar={mem?.avatar}
                                                                    memberId={mem?.id}
                                                                    index={memIndex}
                                                                    key={memIndex}
                                                                    text={mem?.fullname ?? 'Not Found'}
                                                                    textColor={mem.confirmed ? colors.darkGray : mem.confirmed === false ? colors.darkGray : colors.midGray}
                                                                    color={colors.lightGray}
                                                                    confirmedTime={mem.confirmedTime}
                                                                    secondaryText={showDetails && mem.confirmedDetails ? mem.confirmedDetails : null}
                                                                    secondaryTextColor={colors.midGray}
                                                                    secondaryTextSize={12}
                                                                    notified={mem.notif_email_dt || mem.notif_sms_dt}
                                                                    confirmed={mem.confirmed}
                                                                    declinedReason={canViewReasons ? mem.confirmed_reason : null}
                                                                    style={{ paddingLeft: 40, paddingTop: memIndex === 0 ? 3 : 0 }}
                                                                    router={this.props.router}
                                                                    onTextDoubleClick={() => {
                                                                        if (auth?.admin) {
                                                                            var userLink = `${window.location.origin}/event?id=${event?.id}&member=${mem.id}`;
                                                                            navigator?.clipboard?.writeText(userLink);
                                                                        }
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            )
                                        })
                                        }

                                        {!hideHeader && attendingMembers?.length > 0 && !isGuest &&
                                            <Button
                                                style={{ textAlign: 'left', borderRadius: 10, color: colors.darkGray, display: 'flex', marginTop: 30 }}
                                                fluid
                                                onClick={() => this.setState({ showAttending: !showAttending })}
                                            >
                                                {isRussian ? 'ПОСЕЩАЕМОСТЬ' : 'ATTENDING'}
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <Icon name={showAttending ? 'sort down' : 'sort up'}></Icon>
                                                </div>
                                            </Button>
                                        }


                                        {!isGuest && showAllParticipants && showAttending && attendingMembers?.length > 0 &&
                                            <div ref={this.attendeesContainer} style={{ paddingTop: 20, width: '100%' }}>
                                                {attendingMembers?.map((member, fieldIndex) => {
                                                    var notif_sms_dt = parseJSON(member.notif_sms_dt);
                                                    var notif_email_dt = parseJSON(member.notif_email_dt);
                                                    var isNotified = isValid(notif_sms_dt) || isValid(notif_email_dt);
                                                    if (!member?.confirmed) return null;

                                                    return (
                                                        <div style={{ display: 'inline-block', margin: 3 }} key={member.id}>
                                                            <Avatar
                                                                memberId={member?.id}
                                                                avatar={member?.avatar}
                                                                tooltip={member?.fullname ?? 'Not Found'}
                                                                borderEnabled
                                                                confirmed={member?.confirmed}
                                                                enableOpacity
                                                                notified={isNotified}
                                                                style={{ width: 50 }}
                                                                noPadding
                                                                router={this.props.router}
                                                            />
                                                        </div>)
                                                })}
                                            </div>
                                        }

                                        {!isGuest && showAttending && event?.attending?.map((i, fieldIndex) => {
                                            // sort descending order..	
                                            const goingMembers = i.members
                                                ?.filter(m => m.confirmed === true)
                                                ?.sort(function (a, b) {
                                                    if (a.confirmed_dt < b.confirmed_dt) { return 1; }
                                                    if (a.confirmed_dt > b.confirmed_dt) { return -1; }
                                                    return 0;
                                                });

                                            const pendingMembers = i.members
                                                ?.filter(m => m.confirmed === undefined)
                                                ?.sort(function (a, b) {
                                                    var a_notif_dt = a.notif_sms_dt ?? a.notif_email_dt;
                                                    var b_notif_dt = b.notif_sms_dt ?? b.notif_email_dt;
                                                    if (a_notif_dt < b_notif_dt) { return 1; }
                                                    if (a_notif_dt > b_notif_dt) { return -1; }
                                                    return 0;
                                                });

                                            const declinedMembers = i.members
                                                ?.filter(m => m.confirmed === false)
                                                ?.sort(function (a, b) {
                                                    if (a.confirmed_dt < b.confirmed_dt) { return 1; }
                                                    if (a.confirmed_dt > b.confirmed_dt) { return -1; }
                                                    return 0;
                                                });

                                            return (
                                                <div key={fieldIndex} style={{ marginTop: 25 }}>
                                                    <Table basic={isMobile} stackable={false} celled style={{ textAlign: isMobile ? 'left' : 'center', borderColor: colors.lightGray }}>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                {goingMembers?.length > 0 &&
                                                                    <Table.Cell style={{ margin: 10 }}>
                                                                        <div style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.setState({ attendingModal: hideMembers ? null : { attendees: goingMembers, title: 'Going' } });
                                                                                ReactGA.event({ category: 'ScheduleView', action: `View Going Members` });
                                                                            }}>
                                                                            <Icon name='check circle' style={{ color: colors.accept }} /> {goingMembers?.length} Going
                                                                        </div>
                                                                    </Table.Cell>
                                                                }
                                                                {pendingMembers.length > 0 &&
                                                                    <Table.Cell style={{ margin: 10 }}>
                                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                            this.setState({ attendingModal: hideMembers ? null : { attendees: pendingMembers, title: 'Pending' } });
                                                                            ReactGA.event({ category: 'ScheduleView', action: `View Pending Members` });
                                                                        }}>
                                                                            <Icon name='wait' style={{ color: colors.pending }} /> {pendingMembers.length} Pending
                                                                        </div>
                                                                    </Table.Cell>
                                                                }
                                                                {declinedMembers.length > 0 &&
                                                                    <Table.Cell style={{ margin: 10 }}>
                                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                            this.setState({ attendingModal: hideMembers ? null : { attendees: declinedMembers, title: 'Not Going' } });
                                                                            ReactGA.event({ category: 'ScheduleView', action: `View Declined Members` });
                                                                        }}>
                                                                            <Icon name='times circle' style={{ color: colors.decline }} /> {declinedMembers.length} Not Going
                                                                        </div>
                                                                    </Table.Cell>
                                                                }
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>
                                                </div>
                                            )
                                        })
                                        }

                                    </div>

                                    {this.state.event?.location &&
                                        <Button
                                            style={{ textAlign: 'left', borderRadius: 10, color: colors.darkGray, display: 'flex', marginTop: 30 }}
                                            fluid
                                            onClick={() => this.setState({ showLocation: !showLocation })}
                                        >
                                            {isRussian ? 'РАСПОЛОЖЕНИЕ' : 'LOCATION'}
                                            <div style={{ width: '100%', textAlign: 'right' }}>
                                                <Icon name={showLocation ? 'sort down' : 'sort up'}></Icon>
                                            </div>
                                        </Button>
                                    }

                                    {showLocation &&
                                        <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
                                            {this.state.event?.location &&
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginTop: 20, marginBottom: 'auto', marginRight: 10 }}>
                                                        <Icon name='point' style={{ color: colors.midGray }} size='large' />
                                                    </div>
                                                    <div style={{ lineHeight: 1.5 }}>
                                                        <div style={{ fontSize: 15, color: colors.black }}>{this.state.event?.location?.split(',')[0]}</div>
                                                        <div style={{ fontSize: 14, color: colors.darkGray }}>{this.state.event?.location}</div>
                                                        <div style={{ paddingTop: 0 }}>
                                                            <HyperLink text={showMap
                                                                ? isRussian ? 'Скрыть карту' : 'Hide map'
                                                                : isRussian ? 'Показать на карте' : 'Show map'}
                                                                onClick={() => {
                                                                    this.setState({ showMap: !showMap });
                                                                    ReactGA.event({ category: 'ScheduleView', action: `Toggle Map` });
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* free version of google maps api w no quotes */}
                                            {this.state.event?.location && showMap &&
                                                <iframe
                                                    title={this.state.event?.location}
                                                    width='100%'
                                                    height='300'
                                                    style={{ border: 0, marginTop: 10 }}
                                                    loading="lazy"
                                                    src={`https://www.google.com/maps/embed/v1/search?q=${event?.location}&key=AIzaSyBjSXgk9L5PlmJdFW4RKqmD05WmEj_SZ_k`}
                                                />
                                            }
                                        </div>
                                    }

                                    {this.state.event?.tags &&
                                        <SegmentLineItem
                                            header
                                            text={`TAGS`}
                                            textWeight={500}
                                            textColor={colors.midGray}
                                            textSize={14}
                                            style={{ marginBottom: 10, marginTop: 30 }}
                                            router={this.props.router}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    }

                    {!isGuest && !notFound &&
                        <div style={{ marginTop: 50, width: '100%' }}>
                            <CommentsSectionAsync parentType='event' parentId={event?.id} />
                        </div>
                    }

                    <SmoothModal
                        open={attendingModal !== null}
                        onClose={() => this.setState({ attendingModal: null })}
                        size='tiny'
                        header={attendingModal ? `${attendingModal?.attendees?.length} ${attendingModal?.title}` : ''}
                        hideCloseBtn={!isMobile}
                    >
                        <Modal.Content style={{ marginTop: isMobile ? 0 : -15 }}>
                            {attendingModal?.attendees?.map((mem, memIndex) => {
                                var confirmed_dt = parseJSON(mem.confirmed_dt);
                                var notif_sms_dt = parseJSON(mem.notif_sms_dt);
                                var notif_email_dt = parseJSON(mem.notif_email_dt);

                                var isNotified = isValid(notif_sms_dt) || isValid(notif_email_dt);
                                var hasDeclined = mem.confirmed === false;
                                var member = members[mem.id];
                                var fullname = member?.fullname ?? 'Not Found';

                                var confirmedTime = null;
                                if (isValid(confirmed_dt)) confirmedTime = format(confirmed_dt, 'MMMM do') + ' at ' + format(confirmed_dt, 'h:mm aa');

                                var notifTime = null;
                                if (isValid(notif_email_dt)) notifTime = format(notif_email_dt, 'MMMM do') + ' at ' + format(notif_email_dt, 'h:mm aa');
                                if (isValid(notif_sms_dt)) notifTime = format(notif_sms_dt, 'MMMM do') + ' at ' + format(notif_sms_dt, 'h:mm aa');

                                return (
                                    <div style={{ display: 'flex', height: 45 }}>
                                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                            <Avatar
                                                memberId={member?.id}
                                                avatar={member?.avatar}
                                                tooltip={member?.fullname ?? 'Not Found'}
                                                disableTooltip
                                                confirmed={mem.confirmed}
                                                notified={isNotified}
                                                showConfirmedStatus
                                                router={this.props.router}
                                            />
                                        </div>
                                        <div style={{ marginLeft: 15 }}>
                                            <div style={{ display: 'flex', color: hasDeclined ? colors.decline : colors.black }}>
                                                <div style={{ paddingTop: 0 }}>{fullname}</div>
                                                {canViewReasons && mem.confirmed_reason &&
                                                    <Popup
                                                        trigger={<Icon name='chat' style={{ paddingLeft: 5, color: colors.midGray }} />}
                                                        content={
                                                            <div>
                                                                <div style={{ fontStyle: 'italic' }}>"{mem.confirmed_reason}"</div>
                                                                {confirmedTime && <div style={{ color: colors.midGray, paddingTop: 10 }}>{confirmedTime}</div>}
                                                            </div>
                                                        }
                                                    />
                                                }
                                            </div>

                                            <div style={{ color: colors.midGray, fontSize: 12 }}>
                                                {confirmedTime && <div>{confirmedTime}</div>}
                                                {!confirmedTime && notifTime && <div>{notifTime}</div>}
                                            </div>

                                            {/* {showDetails && 
												<div style={{color: colors.midGray, fontSize: 12}}>
													{isValid(notif_sms_dt) && <div>SMS sent {format(notif_sms_dt, 'MMMM do')} at {format(notif_sms_dt, 'h:mm aa')}</div>}
													{isValid(notif_email_dt) && <div>Email sent {format(notif_email_dt, 'MMMM do')} at {format(notif_email_dt, 'h:mm aa')}</div>}
												</div>
											} */}
                                        </div>
                                    </div>
                                )
                            })}
                        </Modal.Content>
                    </SmoothModal>

                    <TextEntryForm
                        open={this.state.copiedLink}
                        title={'Copied link to Clipboard!'}
                        subtitle={this.state.copiedLink}
                        hideInput
                        submitLabel='Close'
                        onClose={() => this.setState({ copiedLink: null })}
                    />

                    <TextEntryForm
                        open={declineEvent}
                        title={isRussian ? 'Причина' : 'Leave Comment'}
                        subtitle={isRussian ? '(Необязательно) Пожалуйста, укажите причину' : `Comment (Optional)`}
                        submitLabel={isRussian ? 'Отклонить' : 'Submit Response'}
                        onClose={() => this.setState({ declineEvent: false })}
                        submit={(comment) => {
                            var declineKeys = {
                                'schedule': memberFields?.filter(f => f.type === 'schedule')?.map(f => f.crossKey),
                                'staff': memberFields?.filter(f => f.type === 'staff')?.map(f => f.crossKey),
                                'attending': memberFields?.filter(f => f.type === 'attending')?.map(f => f.crossKey),
                            };
                            // in the event that member is not part of any fields to confirm, but sends confirmation..
                            if (!this.hasFields(declineKeys)) declineKeys.attending.push('1_Attending');
                            this.sendConfirmation({}, declineKeys, comment);
                        }}
                        text={declineReason}
                    />

                </div>
            </div>
        )
    }
}

function SegmentLineItem(props) {
    var {
        index,
        header,
        stacked,
        color,
        userSegment,
        confirmed,
        historicalDecline,
        text,
        textColor,
        textSize,
        textWeight,
        secondaryText,
        secondaryTextColor,
        secondaryTextSize,
        secondaryTextLinked,
        style,
        onTextDoubleClick,
        divider,
        timestamp,
        hasTimestamp,
        avatar,
        declinedReason,
        confirmedTime,
        memberId,
        router,
        notified
    } = props;

    if (!color)
        color = 'none'

    if (!textColor)
        textColor = '#808080';

    if (!secondaryTextColor)
        secondaryTextColor = '#808080';

    if (!textSize)
        textSize = '1rem'

    if (!textWeight)
        textWeight = 'normal'

    var hasAccepted = confirmed === true;
    var hasDeclined = confirmed === false;

    var allStyles =
    {
        borderColor: 'none',
        width: '100%',
        borderRadius: 10,
        backgroundColor: header ? colors.lightGray : null,
        padding: header ? 7 : 0,
    };

    if (style) {
        Object.keys(style).forEach((key) => {
            allStyles[key] = style[key];
        });
    }

    var [copied, setCopied] = useState(false);

    return (
        <div
            style={allStyles}
            stacked={stacked}
        >
            {divider && <Divider />}

            <div style={{
                marginTop: userSegment ? index > 0 ? 5 : 0 : -5,
                display: userSegment || hasTimestamp ? 'flex' : 'inline-block',
                letterSpacing: 1,
            }}>

                {userSegment &&
                    <div style={{ paddingLeft: hasTimestamp ? 70 : 0 }}>
                        <Avatar
                            memberId={memberId}
                            avatar={avatar}
                            tooltip={text ?? 'Not Found'}
                            disableTooltip
                            confirmed={confirmed}
                            notified={notified}
                            enableOpacity
                            style={{ width: 33, height: 33, marginTop: -2, }}
                            router={router}
                            showConfirmedStatus
                        />
                    </div>
                }

                {timestamp &&
                    <div style={{
                        fontSize: 14,
                        fontWeight: textWeight,
                        color: colors.darkGray,
                        minWidth: 70,
                        marginRight: 10
                    }}>
                        {`${timestamp.format('hh:mm A')}`}
                    </div>
                }

                <div style={{ paddingLeft: hasTimestamp ? 5 : 5 }}>
                    <div style={{ display: 'inline' }}>
                        <Popup content={'Copied link'} disabled={!copied} onClose={() => setCopied(false)} trigger={
                            <div onDoubleClick={() => {
                                if (userSegment) {
                                    onTextDoubleClick();
                                    setCopied(true);
                                }
                            }}>
                                <div
                                    style={{
                                        fontSize: textSize,
                                        fontWeight: textWeight,
                                        color: textColor,
                                        paddingLeft: header ? 10 : 0,
                                        opacity: userSegment ? hasAccepted || hasDeclined ? 1 : .4 : 1,
                                        display: 'flex',
                                        paddingTop: userSegment ? 3 : 0,
                                        textDecoration: historicalDecline ? 'line-through' : undefined,
                                    }}>

                                    {text}

                                    {declinedReason &&
                                        <Popup
                                            trigger={<Icon name='chat' style={{ paddingLeft: 5, color: colors.midGray, opacity: .6 }} />}
                                            content={
                                                <div>
                                                    <div style={{ fontStyle: 'italic' }}>"{declinedReason}"</div>
                                                    {confirmedTime && <div style={{ color: colors.midGray, paddingTop: 10 }}>{confirmedTime}</div>}
                                                </div>
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        } />
                    </div>

                    {secondaryText &&
                        <div
                            style={{
                                paddingTop: 5,
                                color: secondaryTextColor,
                                fontSize: secondaryTextSize,
                                letterSpacing: 0.2,
                                whiteSpace: 'pre'
                            }}>
                            {secondaryTextLinked ? <LinkedText key={0} text={secondaryText} /> : secondaryText}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(ScheduleView);